import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { getDeviceName, minutesToHM, timestampToDate } from './map/mapUtil';
import ExportButton from './common/ExportButton';
import MenuTitle from './MenuTitle';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tabs, Tab, Snackbar, InputAdornment, Tooltip, CircularProgress, makeStyles, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Accordion, AccordionSummary, AccordionDetails, Grid, Checkbox, FormControl, InputLabel, Select, MenuItem, Button, TextField, ButtonGroup, IconButton, FormGroup, FormControlLabel, FormLabel, FormHelperText, Switch, Menu, Typography, RadioGroup, Radio, Backdrop, Fab, Fade, Modal } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ErrorIcon from '@material-ui/icons/Error';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import GetAppIcon from '@material-ui/icons/GetApp';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';

import { DataGrid, ptBR } from '@material-ui/data-grid';

//https://github.com/Hacker0x01/react-datepicker/
//https://reactdatepicker.com/
//https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from 'date-fns/locale/pt';

import moment from 'moment';
import MomentUtils from '@date-io/moment';

import MainToolbar from './MainToolbar';

import t from './common/localization';
import { validateEmail } from './common/stringUtils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'

const filterColor = '#78A9FF50'

const colorRed = 'rgba(255, 0, 0, 0.3)';
const colorYellow = 'rgba(255, 255, 0, 0.3)';
const colorGreen = 'rgba(0, 255, 0, 0.3)';
const colorBlue = 'rgba(0, 0, 255, 0.3)';

const useStyles = makeStyles(theme => ({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: 1,
      overflow: 'auto',
      padding: theme.spacing(2),
      padding: '5px'
    },
    dataGrid: {
      '&.MuiDataGrid-root .MuiDataGrid-cell:focus': { outline: 'none' },
      outline: 'none'
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(8),
      right: theme.spacing(2),
    },
    highlightedRow: {
      backgroundColor: "#00000015",
    },
  }));

const TachoFiles = () => {

    const classes = useStyles();

    const [mapStyle, setMapStyle] = useState("streets-v12");
    const [infoDialog, setInfoDialog] = useState(false);
    const [list, setList] = useState([]);
    const [selectedTacho, setSelectedTacho] = useState({});
    const [loading, setLoading] = useState(false);
    const [filterText, setFilterText] = useState(global.TachoFileSearchText || "");
    const [dialogTab, setDialogTab] = useState('details');

    const [selectedIds, setSelectedIds] = useState([]);
    const toggleSelectRow = (id) => {
      setSelectedIds((prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((rowId) => rowId !== id)
          : [...prevSelected, id]
      );
    };
    
    const groups = useSelector(state => state.groups.items);

    useEffect(() => {
      getTachos();
    }, [])

    global.selectedMenu = "/tacho_files"

    const getPeriodicity = (periodicity) => {
      if (periodicity == "Daily") return "Diário";
      if (periodicity == "Weekly") return "Semanal";
      if (periodicity == "Monthly") return "Mensal";
      if (periodicity == "Custom") return "Manual";
      if (periodicity == "Never") return "Manual";
      return "";
    }

    const openInfoDialog = (tacho, item) => {
      item.newStatusDetails = '';
      setSelectedTacho(item)
      setDialogTab('details')
      setInfoDialog(true);
    }

    const closeInfoDialog = (tacho) => {
      setInfoDialog(false);
    }


    const toggleSelectAll = () => {
      if (selectedIds.length==0) {
        setSelectedIds(filterList(list).map((item) => item.id));
      } else {
        setSelectedIds([])
      }      
    };

    const sendFileToClient = (filename, type, data) => {
      const link = document.createElement("a");
      link.href = "data:"+type+";charset=UTF-8," + encodeURIComponent(data);;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    const downloadSelectedFiles = async () => {
      setLoading(true);

      const response = await fetch('/report/api.php/tacho_files', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({fileIds: selectedIds}),
      });

      if (response.ok) {

        const disposition = response.headers.get("Content-Disposition");
        let filename = "downloaded-file"; // Default filename
        if (disposition && disposition.includes("filename=")) {
          filename = disposition
            .split("filename=")[1]
            .replace(/"/g, ""); // Remove quotes around the filename
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
  
        // Revoke the blob URL
        window.URL.revokeObjectURL(url);

        //sendFileToClient("aaa", "application/octet-stream", response.blob())
      }

      setLoading(false);
    }
    
    const filterList = (list) => {
      return list.filter(item => 
        (filterText != '' ?
          (
            (item.name && item.name.toLowerCase().includes(filterText.toLowerCase()))
            || (item.license_plate && item.license_plate.toLowerCase().includes(filterText.toLowerCase()))
          )
          : true)
      ).map((row) => ({
        ...row,
        isSelected: selectedIds.includes(row.id),
        toggleSelect: toggleSelectRow,
      }));
    }
    
    const columns = [
        {
          field: 'id',
          headerName: (
            <Checkbox
              style={{ marginRight: '25px' }}
              color='primary'
              checked={selectedIds.length>0 && selectedIds.length == filterList(list).length}
              indeterminate={selectedIds.length>0 && selectedIds.length < filterList(list).length}
              onClick={toggleSelectAll}
            />
          ),
          headerAlign: 'center',
          parser: (item) => "",
          sortable: false,
          renderCell: (item) => (
            <span style={{  }}>
              <Checkbox
                style={{ padding: '0px', marginRight: '5px', marginBottom: '5px'}}
                color='primary'
                checked={item.row.isSelected || false}
                onClick={() => item.row.toggleSelect(item.id)}
              />
              <Tooltip title={t('file') + ": " + item.row.filename}>
                <a href={"/report/api.php/tacho_file/"+item.id} style={{ position: 'relative', top: '6px' }}>
                  <GetAppIcon/>
                </a>
              </Tooltip>
            </span>
          ),
          flex: 10,
          minWidth: 70,
        },
        {
          field: 'name',
          headerName: "Entidade",
          headerAlign: 'center',
          parser: (item) => item.name,
          renderCell: (item) => <>
            <span style={{ color: '#888888', margin: '10px 5px 0px 0px'}}>
              { item.row.file_type == "D" ? <AccountBoxIcon/> : <DirectionsCarIcon/>}
            </span>
            {item.row.name}
          </>,
          sortable: true,
          flex: 100,
          minWidth: 100,
        },
        {
          field: 'download_date',
          headerName: "Data de descarga",
          headerAlign: 'center',
          align: 'center',
          parser: (item) => item.download_date,
          sortable: true,
          flex: 50,
          minWidth: 100,
        },
        {
          field: 'data_from',
          headerName: "Período",
          headerAlign: 'center',
          align: 'center',
          parser: (item) => (item.data_from && item.data_to) ? (item.data_from + " - " + item.data_to) : '',
          renderCell: (item) => (item.row.data_from && item.row.data_to) ? (item.row.data_from + " - " + item.row.data_to) : '',
          sortable: true,
          flex: 100,
          //minWidth: 50,
        },
        {
          field: 'days',
          headerName: "Dias",
          headerAlign: 'center',
          align: 'center',
          parser: (item) => item.days,
          renderCell: (item) => item.days,
          sortComparator: (a, b) => Number(a) - Number(b),
          sortable: true,
          flex: 30,
          minWidth: 120,
        },
        {
          field: 'file_size',
          headerName: "Tamanho",
          headerAlign: 'center',
          align: 'center',
          parser: (item) => Math.round(item.file_size/1024) + " kB",
          renderCell: (item) => Math.round(item.row.file_size/1024) + " kB",
          sortComparator: (a, b) => Number(a.replace(" kB", "")) - Number(b.replace(" kB", "")),
          sortable: true,
          flex: 30,
          minWidth: 120,
        },
        {
          field: 'periodicity',
          headerName: "Agendamento",
          headerAlign: 'center',
          align: 'center',
          parser: (item) => getPeriodicity(item.periodicity),
          renderCell: (item) => getPeriodicity(item.row.periodicity),
          sortable: true,
          flex: 50,
          minWidth: 150,
        },

      ];

    const getTachos = async () => {
      setLoading(true);
      const response = await fetch(`/report/api.php/tacho_files`, { method: 'GET' });
      if (response.ok) {
        var result = await response.json();
        console.log("Tacho Files", result)
        setList(result);
        setLoading(false);
      }
    }

    /*
    https://v4.mui.com/api/data-grid/data-grid/
    https://v4.mui.com/components/data-grid/columns/#data-grid-columns
    https://v4.mui.com/components/data-grid/rows/#data-grid-rows
    */

    return (
      <div className={classes.root}>
        
        <MainToolbar />

        <MenuTitle title="Descargas de tacógrafo" style={{ margin: '0px', marginTop: '5px' }} />

        <div style={{ display: 'flex', marginBottom: '5px' }}>

          <div style={{ display: 'flex' }}>
            
            <TextField
              style={{
                marginLeft: '5px',
                minWidth: '400px',
                position: 'relative',
                top: '5px'
              }}
              variant="outlined"
              value={filterText}
              onChange={(tacho) => {setFilterText(tacho.target.value); global.TachoFileSearchText = tacho.target.value}}
              placeholder={t('search')}
              InputProps={{
                style: {
                  height: '30px',
                  padding: '0px 0px 0px 10px',
                  borderRadius: '50px',
                  fontSize: '0.8rem',
                  backgroundColor: filterText == "" ? '' : filterColor,
                },
                startAdornment: (
                  <div>
                    <InputAdornment position='end'>
                      <SearchIcon />
                    </InputAdornment>
                    <InputAdornment style={{right: '0px', position: 'absolute'}} position='end'>
                      <IconButton
                        onClick={() => {setFilterText(''); global.TachoFileSearchText = ''}}
                        onMouseDown={() => {setFilterText(''); global.TachoFileSearchText = ''}}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  </div>
                )
              }}
            />

            <ExportButton
              title='Descargas de tacógrafo'
              columns={columns}
              data={filterList(list)}
              filename='TachoFiles'
              style={{marginTop: '5px'}}
            />

            <Tooltip title="Actualizar dados">
              <IconButton
                onClick={() => { getTachos(false) }}
                style={{padding: '0px', color: '#78A9FF', marginLeft: '10px', top: '3px'}}
                >
                  <FontAwesomeIcon icon={faSyncAlt} style={{color: '#78A9FF', fontSize: '0.8em'}}/>
              </IconButton>
            </Tooltip>
            
            <span style={{ marginLeft: '10px', position: 'relative', top: '10px', whiteSpace: 'nowrap' }}>
              { filterList(list).length + " " + (filterList(list).length == 1 ? t('file') : t('files') ) }
            </span>
            
          </div>

          { selectedIds.length>0 &&
              <span style={{ float: 'right', position: 'absolute', right: '10px', whiteSpace: 'nowrap' }}>
                { selectedIds.length } Ficheiros seleccionados
                &nbsp;
                <Button size="small" onClick={downloadSelectedFiles} color="primary" variant="outlined" style={{  }}>
                  Download
                </Button>
              </span>
            }

        </div>

        <div className={classes.content}>

          <Dialog open={infoDialog} onClose={closeInfoDialog} maxWidth="xl">
            <DialogContent style={{ minWidth: '450px' }}>
              <DialogContentText>

                <Tabs
                  value={dialogTab}
                  onChange={(tacho, newValue) => setDialogTab(newValue)}
                  variant="fullWidth"
                  TabIndicatorProps={{style: {background: '#78A9FF'}}}
                  textColor="primary"
                  style={{minHeight: '0px'}}
                  >
                    <Tab label="Detalhes" value="details"/>
                </Tabs>

                <div style={{ marginTop: '20px' }}>

                  { dialogTab == 'details' && (
                    <div style={{ display: 'flex' }}>

                      { selectedTacho.latitude && selectedTacho.longitude &&
                        <span>
                          <a href={`https://www.google.com/maps/place/${selectedTacho.latitude},${selectedTacho.longitude}`} target="_blank">
                            <img src={`https://api.mapbox.com/styles/v1/mapbox/${mapStyle}/static/pin-l+00b3ff(${selectedTacho.longitude},${selectedTacho.latitude}/${selectedTacho.longitude},${selectedTacho.latitude},15,0/300x300?access_token=pk.eyJ1IjoidHJhY2tpbmdpbnRlZ3JhdGlvbiIsImEiOiJja2t0cGJiZmYwcXFnMnFwYmFsczNwdjAyIn0.jFlT6lSwgMBsyIKvSEIqGQ`} />
                          </a><br></br>
                          <FormControl component="fieldset">
                            <RadioGroup row value={mapStyle} onChange={(e, value) => setMapStyle(value)}>
                              <FormControlLabel value="streets-v12" control={<Radio color="primary" />} label={t('layerRoad')} />
                              <FormControlLabel value="satellite-streets-v12" control={<Radio color="primary" />} label={t('layerSatellite')} />
                            </RadioGroup>
                          </FormControl>
                        </span>
                      }
                      <table
                      style={{
                        paddingLeft: '10px'
                      }}>
                        <tbody>
                          <tr>
                            <td><b>{t('vehicle')}:</b></td>
                            <td>{selectedTacho.deviceName}</td>
                          </tr>
                          <tr>
                            <td><b>{t('licensePlate')}:</b></td>
                            <td>{selectedTacho.deviceLicensePlate}</td>
                          </tr>
                          <tr>
                            <td><b>{t('group')}:</b></td>
                            <td>{groups[selectedTacho.groupId] ? groups[selectedTacho.groupId].name : ''}</td>
                          </tr>

                          <tr>
                            <td><b>{t('date')}:</b></td>
                            <td>{selectedTacho.costDate}</td>
                          </tr>

                          <tr>
                            <td><b>{t('type')}:</b></td>
                            <td>{selectedTacho.type === "M" ? "Manual" : "App"}</td>
                          </tr>
                          { selectedTacho.operatorName &&
                            <tr>
                              <td><b>{t('operator')}:</b></td>
                              <td>{selectedTacho.operatorName + (selectedTacho.operatorCode ? ` (${selectedTacho.operatorCode})` : '')}</td>
                            </tr>
                          }
                          <tr>
                            <td><b>{t('fuel')}:</b></td>
                            <td>{t("fuel_"+selectedTacho.fuelType)}</td>
                          </tr>
                          <tr>
                            <td><b>{t('litres')}:</b></td>
                            <td>{selectedTacho.litres}</td>
                          </tr>
                          <tr>
                            <td><b>{t('km')}:</b></td>
                            <td>{selectedTacho.km}</td>
                          </tr>
                          <tr>
                            <td><b>{t('hours')}:</b></td>
                            <td>{selectedTacho.hours}</td>
                          </tr>
                          <tr>
                            <td><b>{t('file')}:</b></td>
                            <td>{selectedTacho.fileName}</td>
                          </tr>
                          <tr>
                            <td><b>{t('details')}:</b></td>
                            <td>{selectedTacho.details}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>

              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={closeInfoDialog} color="primary" variant="outlined" style={{paddingLeft: '40px', paddingRight: '40px' }}>
                {t('back')}
              </Button>
            </DialogActions>

          </Dialog>

          <Backdrop className={classes.backdrop} open={loading} onClick={e => setLoading(false)} style={{ zIndex: 999 }}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <DataGrid
            className={classes.dataGrid}
            rows={filterList(list)}
            getRowId={(row) => row.id}
            columns={columns}
            pageSize={100}
            checkboxSelection={false}
            disableSelectionOnClick={true}
            headerHeight={30}
            rowHeight={30}
            // headerHeight={56*2}
            disableColumnMenu={true}
            localeText={ptBR.props.MuiDataGrid.localeText}
            getRowClassName={(params) =>
              selectedIds.includes(params.row.id) ? classes.highlightedRow : ""
            }
          />
            
        </div>
      </div>
    );
}

export default TachoFiles;
