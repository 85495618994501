import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { getDeviceName, minutesToHM, timestampToDate } from './map/mapUtil';
import ExportButton from './common/ExportButton';
import MenuTitle from './MenuTitle';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tabs, Tab, Snackbar, InputAdornment, Tooltip, CircularProgress, makeStyles, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Accordion, AccordionSummary, AccordionDetails, Grid, Checkbox, FormControl, InputLabel, Select, MenuItem, Button, TextField, ButtonGroup, IconButton, FormGroup, FormControlLabel, FormLabel, FormHelperText, Switch, Menu, Typography, RadioGroup, Radio, Backdrop, Fab, Fade, Modal } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ErrorIcon from '@material-ui/icons/Error';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

import { DataGrid, ptBR } from '@material-ui/data-grid';

//https://github.com/Hacker0x01/react-datepicker/
//https://reactdatepicker.com/
//https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from 'date-fns/locale/pt';

import moment from 'moment';
import MomentUtils from '@date-io/moment';

import MainToolbar from './MainToolbar';

import t from './common/localization';
import { validateEmail } from './common/stringUtils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'

const filterColor = '#78A9FF50'

const colorRed = 'rgba(255, 0, 0, 0.3)';
const colorYellow = 'rgba(255, 255, 0, 0.3)';
const colorGreen = 'rgba(0, 255, 0, 0.3)';
const colorBlue = 'rgba(0, 0, 255, 0.3)';

const useStyles = makeStyles(theme => ({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: 1,
      overflow: 'auto',
      padding: theme.spacing(2),
      padding: '5px'
    },
    dataGrid: {
      '&.MuiDataGrid-root .MuiDataGrid-cell:focus': { outline: 'none' },
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(8),
      right: theme.spacing(2),
    },
    dataGridHeader: {
      whiteSpace: 'normal',
      lineHeight: '10px',
    },
  }));

const TachoDrivers = () => {

    const classes = useStyles();

    const [mapStyle, setMapStyle] = useState("streets-v12");
    const [infoDialog, setInfoDialog] = useState(false);
    const [list, setList] = useState([]);
    const [selectedTacho, setSelectedTacho] = useState({});
    const [loading, setLoading] = useState(false);
    const [filterText, setFilterText] = useState(global.TachoDriverSearchText || "");
    const [dialogTab, setDialogTab] = useState('details');
    
    const groups = useSelector(state => state.groups.items);

    useEffect(() => {
      getTachos();
    }, [])

    global.selectedMenu = "/tacho_drivers"

    const working_state = [
       {id: 'Descanso', color: colorYellow},
       {id: 'Disponível', color: colorBlue},
       {id: 'Trabalho', color: colorGreen},
       {id: 'Condução', color: colorGreen},
       {id: '', color: ''},
       {id: '', color: ''},
       {id: 'Erro', color: colorRed},
       {id: 'Indisponível', color: colorRed},
    ]

    const openInfoDialog = (tacho, item) => {
      item.newStatusDetails = '';
      setSelectedTacho(item)
      setDialogTab('details')
      setInfoDialog(true);
    }

    const closeInfoDialog = (tacho) => {
      setInfoDialog(false);
    }

    const columns = [
        {
          field: 'working_state',
          headerName: "Actividade",
          parser: (item) => working_state[item.working_state].id,
          sortable: true,
          minWidth: 90,
          renderCell: (item) => (
            <div
              //onClick={(event) => {openInfoDialog(event, item.row)}}
              style={{
                backgroundColor: working_state[item.row.working_state].color,
                height: '25px',
                fontSize: '0.7em',
                border: 'none',
                minWidth: '70px',
                fontWeight: '600',
                borderRadius: '50px',
                textAlign: 'center',
                textTransform: 'uppercase',
                lineHeight: '25px',
              }}
              variant="outlined">
                {working_state[item.row.working_state].id}
            </div>
          ),
        },
        {
          field: 'name',
          headerName: t('driver'),
          parser: (item) => item.name + " " + item.surname,
          renderCell: (item) => (item.row.name + " " + item.row.surname),
          sortable: true,
          flex: 1.25,
          minWidth: 160,
        },
        {
          field: 'license_plate',
          headerName: "Último veículo",
          parser: (item) => item.license_plate,
          sortable: true,
          flex: 1,
          minWidth: 100,
        },
        {
          field: 'last_seen',
          headerName: t('lastUpdate'),
          parser: (item) => item.last_seen,
          sortable: true,
          flex: 1,
          minWidth: 100,
        },
        {
          field: 'Driver1RemainingTimeUntilNextBreakOrRest',
          headerName: "Tempo restante até à próxima pausa/repouso",
          valueGetter: (item) => minutesToHM(item.row.counters.Driver1RemainingTimeUntilNextBreakOrRest),
          sortable: true,
          flex: 1,
          minWidth: 100,
        },
        {
          field: 'Driver1DurationOfNextBreakRest',
          headerName: "Duração da próxima pausa/repouso",
          valueGetter: (item) => minutesToHM(item.row.counters.Driver1DurationOfNextBreakRest),
          sortable: true,
          flex: 1,
          minWidth: 100,
        },
        {
          field: 'Driver1TimeLeftUntilNewDailyRestPeriod',
          headerName: "Tempo restante até ao próximo repouso diário",
          valueGetter: (item) => minutesToHM(item.row.counters.Driver1TimeLeftUntilNewDailyRestPeriod),
          sortable: true,
          flex: 1,
          minWidth: 100,
        },
        {
          field: 'Driver1TimeLeftUntilNewWeeklyRestPeriod',
          headerName: "Tempo restante até ao próximo repouso semanal",
          valueGetter: (item) => minutesToHM(item.row.counters.Driver1TimeLeftUntilNewWeeklyRestPeriod),
          sortable: true,
          flex: 1,
          minWidth: 100,
        },

        {
          field: 'Driver1MinimumDailyRest',
          headerName: "Repouso mínimo diário",
          valueGetter: (item) => minutesToHM(item.row.counters.Driver1MinimumDailyRest),
          sortable: true,
          flex: 1,
          minWidth: 100,
        },
        {
          field: 'Driver1MinimumWeeklyRest',
          headerName: "Repouso mínimo semanal",
          valueGetter: (item) => minutesToHM(item.row.counters.Driver1MinimumWeeklyRest),
          sortable: true,
          flex: 1,
          minWidth: 100,
        },

      ];

    const getTachos = async () => {
      setLoading(true);
      const response = await fetch(`/report/api.php/tacho_drivers`, { method: 'GET' });
      if (response.ok) {
        var result = await response.json();
        result.forEach((element, index) => {
          result[index].counters = JSON.parse(result[index].counters)
        });
        console.log("Tacho Drivers", result)
        setList(result);
        setLoading(false);
      }
    }

    const filterList = (list) => {
      return list.filter(item => 
        (filterText != '' ?
          (
            (item.name && item.name.toLowerCase().includes(filterText.toLowerCase()))
            || (item.license_plate && item.license_plate.toLowerCase().includes(filterText.toLowerCase()))
          )
          : true)
      );
    }

    /*
    https://v4.mui.com/api/data-grid/data-grid/
    https://v4.mui.com/components/data-grid/columns/#data-grid-columns
    https://v4.mui.com/components/data-grid/rows/#data-grid-rows
    */

    return (
      <div className={classes.root}>
        
        <MainToolbar />

        <MenuTitle title="Condutores de tacógrafo" style={{ margin: '0px', marginTop: '5px' }} />

        <div style={{ display: 'flex', marginBottom: '5px' }}>

          <div style={{ display: 'flex' }}>
            
            <TextField
              style={{
                marginLeft: '5px',
                minWidth: '200px',
                position: 'relative',
                top: '5px'
              }}
              variant="outlined"
              value={filterText}
              onChange={(tacho) => {setFilterText(tacho.target.value); global.TachoDriverSearchText = tacho.target.value}}
              placeholder={t('search')}
              InputProps={{
                style: {
                  height: '30px',
                  padding: '0px 0px 0px 10px',
                  borderRadius: '50px',
                  fontSize: '0.8rem',
                  backgroundColor: filterText == "" ? '' : filterColor,
                },
                startAdornment: (
                  <div>
                    <InputAdornment position='end'>
                      <SearchIcon />
                    </InputAdornment>
                    <InputAdornment style={{right: '0px', position: 'absolute'}} position='end'>
                      <IconButton
                        onClick={() => {setFilterText(''); global.TachoDriverSearchText = ''}}
                        onMouseDown={() => {setFilterText(''); global.TachoDriverSearchText = ''}}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  </div>
                )
              }}
            />

            <ExportButton
              title='Condutores de tacógrafo'
              columns={columns}
              data={filterList(list)}
              filename='TachoDrivers'
              style={{marginTop: '5px'}}
            />

            <Tooltip title="Actualizar dados">
              <IconButton
                onClick={() => { getTachos(false) }}
                style={{padding: '0px', color: '#78A9FF', marginLeft: '10px', top: '3px'}}
                >
                  <FontAwesomeIcon icon={faSyncAlt} style={{color: '#78A9FF', fontSize: '0.8em'}}/>
              </IconButton>
            </Tooltip>
            
            <span style={{ marginLeft: '10px', position: 'relative', top: '10px', whiteSpace: 'nowrap' }}>
              { filterList(list).length + " " + (filterList(list).length == 1 ? t('driver') : t('drivers') ) }
            </span>
            
          </div>

        </div>

        <div className={classes.content}>

          <Dialog open={infoDialog} onClose={closeInfoDialog} maxWidth="xl">
            <DialogContent style={{ minWidth: '450px' }}>
              <DialogContentText>

                <Tabs
                  value={dialogTab}
                  onChange={(tacho, newValue) => setDialogTab(newValue)}
                  variant="fullWidth"
                  TabIndicatorProps={{style: {background: '#78A9FF'}}}
                  textColor="primary"
                  style={{minHeight: '0px'}}
                  >
                    <Tab label="Detalhes" value="details"/>
                </Tabs>

                <div style={{ marginTop: '20px' }}>

                  { dialogTab == 'details' && (
                    <div style={{ display: 'flex' }}>

                      { selectedTacho.latitude && selectedTacho.longitude &&
                        <span>
                          <a href={`https://www.google.com/maps/place/${selectedTacho.latitude},${selectedTacho.longitude}`} target="_blank">
                            <img src={`https://api.mapbox.com/styles/v1/mapbox/${mapStyle}/static/pin-l+00b3ff(${selectedTacho.longitude},${selectedTacho.latitude}/${selectedTacho.longitude},${selectedTacho.latitude},15,0/300x300?access_token=pk.eyJ1IjoidHJhY2tpbmdpbnRlZ3JhdGlvbiIsImEiOiJja2t0cGJiZmYwcXFnMnFwYmFsczNwdjAyIn0.jFlT6lSwgMBsyIKvSEIqGQ`} />
                          </a><br></br>
                          <FormControl component="fieldset">
                            <RadioGroup row value={mapStyle} onChange={(e, value) => setMapStyle(value)}>
                              <FormControlLabel value="streets-v12" control={<Radio color="primary" />} label={t('layerRoad')} />
                              <FormControlLabel value="satellite-streets-v12" control={<Radio color="primary" />} label={t('layerSatellite')} />
                            </RadioGroup>
                          </FormControl>
                        </span>
                      }
                      <table
                      style={{
                        paddingLeft: '10px'
                      }}>
                        <tbody>
                          <tr>
                            <td><b>{t('vehicle')}:</b></td>
                            <td>{selectedTacho.deviceName}</td>
                          </tr>
                          <tr>
                            <td><b>{t('licensePlate')}:</b></td>
                            <td>{selectedTacho.deviceLicensePlate}</td>
                          </tr>
                          <tr>
                            <td><b>{t('group')}:</b></td>
                            <td>{groups[selectedTacho.groupId] ? groups[selectedTacho.groupId].name : ''}</td>
                          </tr>

                          <tr>
                            <td><b>{t('date')}:</b></td>
                            <td>{selectedTacho.costDate}</td>
                          </tr>

                          <tr>
                            <td><b>{t('type')}:</b></td>
                            <td>{selectedTacho.type === "M" ? "Manual" : "App"}</td>
                          </tr>
                          { selectedTacho.operatorName &&
                            <tr>
                              <td><b>{t('operator')}:</b></td>
                              <td>{selectedTacho.operatorName + (selectedTacho.operatorCode ? ` (${selectedTacho.operatorCode})` : '')}</td>
                            </tr>
                          }
                          <tr>
                            <td><b>{t('fuel')}:</b></td>
                            <td>{t("fuel_"+selectedTacho.fuelType)}</td>
                          </tr>
                          <tr>
                            <td><b>{t('litres')}:</b></td>
                            <td>{selectedTacho.litres}</td>
                          </tr>
                          <tr>
                            <td><b>{t('km')}:</b></td>
                            <td>{selectedTacho.km}</td>
                          </tr>
                          <tr>
                            <td><b>{t('hours')}:</b></td>
                            <td>{selectedTacho.hours}</td>
                          </tr>
                          <tr>
                            <td><b>{t('driver')}:</b></td>
                            <td>{selectedTacho.driverName}</td>
                          </tr>
                          <tr>
                            <td><b>{t('details')}:</b></td>
                            <td>{selectedTacho.details}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>

              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={closeInfoDialog} color="primary" variant="outlined" style={{paddingLeft: '40px', paddingRight: '40px' }}>
                {t('back')}
              </Button>
            </DialogActions>

          </Dialog>

          <Backdrop className={classes.backdrop} open={loading} onClick={e => setLoading(false)} style={{ zIndex: 999 }}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <DataGrid
            className={classes.dataGrid}
            rows={filterList(list)}
            getRowId={(row) => row.uniqueid}
            columns={columns}
            pageSize={100}
            checkboxSelection={false}
            disableSelectionOnClick={true}
            rowHeight={30}
            // headerHeight={56*2}
            disableColumnMenu={true}
            localeText={ptBR.props.MuiDataGrid.localeText}
            classes={{
              columnHeader: classes.dataGridHeader,
            }}
          />
            
        </div>
      </div>
    );
}

export default TachoDrivers;
