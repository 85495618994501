import t from './common/localization';

//Routes
import MainPage from './MainPage';
import Dashboard from './Dashboard';
import EventPanel from './EventPanel';
import RefillPanel from './RefillPanel';
import RefillOperatorsPage from './settings/RefillOperatorsPage';
import RefillOperatorPage from './settings/RefillOperatorPage';
import RefillStationsPage from './settings/RefillStationsPage';
import RefillStationPage from './settings/RefillStationPage';
import DeviceQrCodes from './settings/DeviceQrCodes';
import UsersPage from './settings/UsersPage';
import UserPage from './settings/UserPage';
import DevicesPage from './settings/DevicesPage';
import DevicePage from './settings/DevicePage';
import NotificationsPage from './settings/NotificationsPage';
import NotificationPage from './settings/NotificationPage';
import GroupsPage from './settings/GroupsPage';
import GroupPage from './settings/GroupPage';
import ReplayPage from './map/ReplayPage';
import DriversPage from './settings/DriversPage';
import DriverPage from './settings/DriverPage';
import DriverImportPage from './settings/DriverImportPage';
import InterfacePage from './settings/InterfacePage';
import TemporaryLinksPage from './settings/TemporaryLinksPage';
import TemporaryLinkPage from './settings/TemporaryLinkPage';
import GeofencesPage from './settings/GeofencesPage';
import GeofencePage from './settings/GeofencePage';
import ReportPositions from './report/ReportPositions';
import ReportTrips from './report/ReportTrips';
import ReportActivity from './report/ReportActivity';
import ReportDetailedActivity from './report/ReportDetailedActivity';
import ReportPassengerPerVehicle from './report/ReportPassengerPerVehicle';
import ReportPassengerPerPassenger from './report/ReportPassengerPerPassenger';
import ReportVistaWaste from './report/ReportVistaWaste';
import ReportSonangalpHours from './report/ReportSonangalpHours';
import ReportEcoDriving from './report/ReportEcoDriving';
import ReportRag from './report/ReportRag';
import ReportSpeeding from './report/ReportSpeeding';
import ReportLuandaSpeeding from './report/ReportLuandaSpeeding';
import ReportKm from './report/ReportKm';
import ReportEmissions from './report/ReportEmissions';
import ReportDrivingLimits from './report/ReportDrivingLimits';
import ReportMonthlyKm from './report/ReportMonthlyKm';
import ReportMonthlyHours from './report/ReportMonthlyHours';
import ReportStops from './report/ReportStops';
import reportHours from './report/ReportHours';
import ReporyChartKm from './report/ReporyChartKm';
import ReportFuelAnalysis from './report/ReportFuelAnalysis';
import ReportPto from './report/ReportPto';
import ReportSchedulesPage from './report/ReportSchedulesPage';
import ReportSchedulePage from './report/ReportSchedulePage';
import ReportAlerts from './report/ReportAlerts';
import ReportZones from './report/ReportZones';
import ReportCastelDaily from './report/ReportCastelDaily';
import ReportCastelMonthly from './report/ReportCastelMonthly';
import ReportCastelMonthlyDetailed from './report/ReportCastelMonthlyDetailed';
import ReportGroupHours from './report/ReportGroupHours';
import ReportPerformance from './report/ReportPerformance';
import ReportGroupKm from './report/ReportGroupKm';
import ReportMonthlyLiters from './report/ReportMonthlyLiters';
import ReportBlock from './report/ReportBlock';
import MaintenanceSummaryPage from './management/MaintenanceSummaryPage';
import MaintenancesPage from './management/MaintenancesPage';
import MaintenancePage from './management/MaintenancePage';
import CostsPage from './management/CostsPage';
import CostPage from './management/CostPage';
import DocsPage from './management/DocsPage';
import DocPage from './management/DocPage';
import RefillsPage from './management/RefillsPage';
import RefillPage from './management/RefillPage';
import MaintenanceSettingsPage from './management/MaintenanceSettingsPage';
import MaintenanceAnalysisPage from './report/ReportManagementAnalysis';
import MaintenanceCostListPage from './report/ReportManagementCostList';
import TachoDrivers from './TachoDrivers';
import TachoFiles from './TachoFiles';

//Icons
import PlaceIcon from '@material-ui/icons/Place';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FolderIcon from '@material-ui/icons/Folder';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import WorkIcon from '@material-ui/icons/Work';
import SpeedIcon from '@material-ui/icons/Speed';
import Alarm from '@material-ui/icons/Alarm';
import BuildIcon from '@material-ui/icons/Build';
import BarChart from '@material-ui/icons/BarChart';
import AttachMoney from '@material-ui/icons/AttachMoney';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import GroupIcon from '@material-ui/icons/Group';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import SurroundSoundIcon from '@material-ui/icons/SurroundSound';
import RoomIcon from '@material-ui/icons/Room';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DescriptionIcon from '@material-ui/icons/Description';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faRoad } from '@fortawesome/free-solid-svg-icons'
import { faChartBar } from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons'
import { faRecycle } from '@fortawesome/free-solid-svg-icons'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'
import { faTrafficLight } from '@fortawesome/free-solid-svg-icons'
import { faGlassMartini } from '@fortawesome/free-solid-svg-icons'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { faBusinessTime } from '@fortawesome/free-solid-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { faTrophy } from '@fortawesome/free-solid-svg-icons'
import { faBed } from '@fortawesome/free-solid-svg-icons'

export const Permissions = (user) => {
  
  const web_permissions = (user && user.attributes && user.attributes.web_permissions) ? user.attributes.web_permissions : [];

  const temporaryAccess = user && user.email.startsWith('temp_');

  const temporaryAccessHasHistoryAccess = user && user.email.startsWith('temp_') && user.attributes && user.attributes.allowHistory;

  return [
    {
      title: t('map'),
      enabled:
        !web_permissions.includes('only_selected')
        || (web_permissions.includes('only_selected') && web_permissions.includes('module_map')),
      items: [
        {
          enabled: true,
          routes: [
            {path: '/', component: MainPage},
            {path: '/settings/interface/:id?', component: InterfacePage},
          ],
          menu: {
            path: "/",
            icon: (<PlaceIcon style={{color: '#78A9FF'}}/>),
            name: t('onlineMap'),
            tooltip: t('onlineMapTip'),
          },
        },
        {
          enabled:
            !temporaryAccess
            || temporaryAccessHasHistoryAccess,
          routes: [
            {path: '/replay', component: ReplayPage},
          ],
          menu: {
            path: "/replay",
            icon: (<AccessTimeIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('historyModeTip'),
            name: t('historyMode'),
          },
        },
        {
          enabled: false,
          routes: [
            {path: '/dashboard', component: Dashboard},
          ],
          menu: {
            disabled: true,
            path: "/dashboard",
            icon: (<AssessmentIcon style={{color: '#78A9FF'}}/>),
            name: 'Dashboard',
            tooltip: 'Dashboard',
          },
        },
      ]
    },

    {
      title: t('tachograph'),
      load_paths: true,
      enabled:
        !temporaryAccess
        && (
          !web_permissions.includes('only_selected')
          || (web_permissions.includes('only_selected') && web_permissions.includes('module_tacho'))
        )
        && global.client_details && global.client_details.has_tacho,
      items: [
        {
          enabled: global.client_details && global.client_details.has_tacho,
          routes: [
            {path: '/tacho_drivers', component: TachoDrivers},
          ],
          menu: {
            path: "/tacho_drivers",
            icon: (<PersonIcon style={{color: '#78A9FF'}}/>),
            tooltip: "Condutores de Tacógrafo",
            name: "Condutores",
          },
        },
        {
          enabled: global.client_details && global.client_details.has_tacho,
          routes: [
            {path: '/tacho_files', component: TachoFiles},
          ],
          menu: {
            path: "/tacho_files",
            icon: (<DescriptionIcon style={{color: '#78A9FF'}}/>),
            tooltip: "Descargas de Tacógrafo",
            name: "Descargas",
          },
        },
      ]
    },

    {
      title: t('reports'),
      enabled:
        !temporaryAccess
        && (
          !web_permissions.includes('only_selected')
          || (web_permissions.includes('only_selected') && web_permissions.includes('module_reports'))
        ),
      items: [
        {
          enabled: true,
          routes: [
            {path: '/report/trips', component: ReportTrips},
          ],
          menu: {
            path: "/report/trips",
            icon: (<PlayCircleFilledIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('tripsTip'),
            name: t('trips'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/report/activity', component: ReportActivity},     
          ],
          menu: {
            path: "/report/activity",
            icon: (<WorkIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('activityTip'),
            name: t('activity'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/report/speeding', component: ReportSpeeding},       
          ],
          menu: {
            path: "/report/speeding",
            icon: (<SpeedIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('speedTip'),
            name: t('speed'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/report/km', component: ReportKm},
          ],
          menu: {
            path: "/report/km",
            icon: (<FontAwesomeIcon icon={faRoad} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: t('kmTip'),
            name: t('km'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/report/monthly_km', component: ReportMonthlyKm},   
          ],
          menu: {
            path: "/report/monthly_km",
            icon: (<FontAwesomeIcon icon={faCalendarAlt} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: t('reportMonthlyKm'),
            name: t('monthlyKm'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/report/chart_km', component: ReporyChartKm},
          ],
          menu: {
            path: "/report/chart_km",
            icon: (<FontAwesomeIcon icon={faChartBar} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: t('chartKmTip'),
            name: t('chartKm'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/report/hours', component: reportHours},    
          ],
          menu: {
            path: "/report/hours",
            icon: (<FontAwesomeIcon icon={faClock} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: t('reportHours'),
            name: t('hours'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/report/monthly_hours', component: ReportMonthlyHours},   
          ],
          menu: {
            path: "/report/monthly_hours",
            icon: (<FontAwesomeIcon icon={faCalendarAlt} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: t('reportMonthlyHours'),
            name: t('monthlyHours'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/report/stops', component: ReportStops},   
          ],
          menu: {
            path: "/report/stops",
            icon: (<FontAwesomeIcon icon={faHourglassHalf} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: t('reportStops'),
            name: t('reportStops'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/report/fuel_analysis', component: ReportFuelAnalysis},    
          ],
          menu: {
            path: "/report/fuel_analysis",
            icon: (<LocalGasStationIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('fuelAnalysisTip'),
            name: t('fuelAnalysis'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/report/pto', component: ReportPto},
          ],
          menu: {
            path: "/report/pto",
            icon: (<SurroundSoundIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('ptoTip'),
            name: t('pto'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/report/zones', component: ReportZones},    
          ],
          menu: {
            path: "/report/zones",
            icon: (<RoomIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('zonesTip'),
            name: t('zones'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/report/positions', component: ReportPositions}, 
          ],
          menu: {
            path: "/report/positions",
            icon: (<FormatListBulletedIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('positionsTip'),
            name: t('positions'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/report/alerts', component: ReportAlerts},    
          ],
          menu: {
            path: "/report/alerts",
            icon: (<NotificationsIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('alertsTip'),
            name: t('alerts'),
          },
        },
        {
          enabled: web_permissions.includes('block'),
          routes: [
            {path: '/report/block', component: ReportBlock},     
          ],
          menu: {
            path: "/report/block",
            icon: (<FontAwesomeIcon icon={faLock} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: t('reportBlock'),
            name: t('blocking'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/report/schedules', component: ReportSchedulesPage},
            {path: '/report/schedule/:id?', component: ReportSchedulePage}, 
          ],
          menu: {
            path: "/report/schedules",
            icon: (<Alarm style={{color: '#78A9FF'}}/>),
            tooltip: t('schedulerTip'),
            name: t('scheduler'),
          },
        },
      ]
    },

    {
      title: t('specificReports'),
      enabled:
        !temporaryAccess
        && (
          web_permissions.includes('report_detailed_activity')
          || web_permissions.includes('report_luanda_speeding')
          || web_permissions.includes('report_vista_waste')
          || web_permissions.includes('report_sonangalp_hours')
          || web_permissions.includes('report_eco_driving')
          || web_permissions.includes('report_rag')
          || web_permissions.includes('report_castel')
          || web_permissions.includes('report_group_km')
          || web_permissions.includes('report_group_hours')
          || web_permissions.includes('report_performance')
          || web_permissions.includes('report_driving_limits')
          ),
      items: [
        {
          enabled: web_permissions.includes('report_detailed_activity'),
          routes: [
            {path: '/report/detailed_activity', component: ReportDetailedActivity},
          ],
          menu: {
            path: "/report/detailed_activity",
            icon: (<FindInPageIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('positionsTip'),
            name: t('detailedActivity'),
          },
        },
        {
          enabled: web_permissions.includes('report_luanda_speeding'),
          routes: [
            {path: '/report/luanda_speeding', component: ReportLuandaSpeeding},
          ],
          menu: {
            path: "/report/luanda_speeding",
            icon: (<SpeedIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('luandaSpeedingTip'),
            name: t('luandaSpeeding'),
          },
        },
        {
          enabled: web_permissions.includes('report_vista_waste'),
          routes: [
            {path: '/report/vista_waste', component: ReportVistaWaste},
          ],
          menu: {
            path: "/report/vista_waste",
            icon: (<FontAwesomeIcon icon={faRecycle} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: "Relatório de actividade da Vista Waste",
            name: "Actividade Vista Waste",
          },
        },
        {
          enabled: web_permissions.includes('report_sonangalp_hours'),
          routes: [
            {path: '/report/sonangalp_hours', component: ReportSonangalpHours},
          ],
          menu: {
            path: "/report/sonangalp_hours",
            icon: (<FontAwesomeIcon icon={faClock} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: t('"Relatório de horas Sonangalp"'),
            name: "Horas Sonangalp",
          },
        },
        {
          enabled: web_permissions.includes('report_eco_driving') && global.showReportEcoDriving && global.has_canbus,
          routes: [
            {path: '/report/eco_driving', component: ReportEcoDriving},
          ],
          menu: {
            path: "/report/eco_driving",
            icon: (<FontAwesomeIcon icon={faLeaf} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: "Relatório de análise de condução",
            name: t('ecoDriving'),
          },
        },
        {
          enabled: web_permissions.includes('report_rag') && global.showReportRag && global.has_canbus,
          routes: [
            {path: '/report/rag', component: ReportRag},
          ],
          menu: {
            path: "/report/rag",
            icon: (<FontAwesomeIcon icon={faTrafficLight} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: "Relatório Red-Ambar-Green",
            name: "RAG",
          },
        },
        {
          enabled: web_permissions.includes('report_castel'),
          routes: [
            {path: '/report/castel_daily', component: ReportCastelDaily},
          ],
          menu: {
            path: "/report/castel_daily",
            icon: (<FontAwesomeIcon icon={faGlassMartini} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: "Relatório Castel diário",
            name: "Castel Diário",
          },
        },
        {
          enabled: web_permissions.includes('report_castel'),
          routes: [
            {path: '/report/castel_monthly', component: ReportCastelMonthly},
          ],
          menu: {
            path: "/report/castel_monthly",
            icon: (<FontAwesomeIcon icon={faGlassMartini} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: "Relatório Castel mensal",
            name: "Castel Mensal",
          },
        },
        {
          enabled: web_permissions.includes('report_castel'),
          routes: [
            {path: '/report/castel_monthly_detailed', component: ReportCastelMonthlyDetailed},
          ],
          menu: {
            path: "/report/castel_monthly_detailed",
            icon: (<FontAwesomeIcon icon={faGlassMartini} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: "Relatório Castel mensal detalhado",
            name: "Castel Mensal Detalhado",
          },
        },
        {
          enabled: web_permissions.includes('report_group_km'),
          routes: [
            {path: '/report/group_km', component: ReportGroupKm},
          ],
          menu: {
            path: "/report/group_km",
            icon: (<FontAwesomeIcon icon={faRoad} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: "Relatório km por grupo",
            name: "Km por grupo",
          },
        },
        {
          enabled: web_permissions.includes('report_group_hours'),
          routes: [
            {path: '/report/group_hours', component: ReportGroupHours},
          ],
          menu: {
            path: "/report/group_hours",
            icon: (<FontAwesomeIcon icon={faBusinessTime} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: "Relatório de horas por grupo",
            name: "Horas por grupo",
          },
        },
        {
          enabled: web_permissions.includes('report_performance'),
          routes: [
            {path: '/report/performance', component: ReportPerformance},
          ],
          menu: {
            path: "/report/performance",
            icon: (<FontAwesomeIcon icon={faTrophy} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: "Relatório de performance",
            name: "Performance",
          },
        },
        {
          enabled: web_permissions.includes('report_emissions'),
          routes: [
            {path: '/report/emissions', component: ReportEmissions},
          ],
          menu: {
            path: "/report/emissions",
            icon: (<FontAwesomeIcon icon={faLeaf} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: "Relatório de emissões CO2",
            name: t('emissions'),
          },
        },
        {
          enabled: web_permissions.includes('report_driving_limits'),
          routes: [
            {path: '/report/driving_limits', component: ReportDrivingLimits},
          ],
          menu: {
            path: "/report/driving_limits",
            icon: (<FontAwesomeIcon icon={faBed} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: "Relatório de limites de condução",
            name: "Limites de condução",
          },
        },
      ]
    },

    {
      title: t('modules'),
      enabled:
        !temporaryAccess
        && (
          web_permissions.includes('module_events')
          || web_permissions.includes('module_refills')
          ),
      items: [
        {
          enabled: web_permissions.includes('module_events'),
          routes: [
            {path: '/events', component: EventPanel},
          ],
          menu: {
            path: "/events",
            icon: (<FontAwesomeIcon icon={faExclamationTriangle} style={{color: '#78A9FF', fontSize: '1.5em'}}/>),
            tooltip: "Central de gestão de alertas",
            name: "Central de Alertas",
          },
        },
        {
          enabled: web_permissions.includes('module_refills'),
          routes: [
            {path: '/refills', component: RefillPanel},
            {path: '/management/refill/:id?', component: RefillPage},
            {path: '/settings/refill_operator/:id?', component: RefillOperatorPage},
            {path: '/settings/refill_operators', component: RefillOperatorsPage},
            {path: '/settings/refill_station/:id?', component: RefillStationPage},
            {path: '/settings/refill_stations', component: RefillStationsPage},
            {path: '/settings/device_qr_codes', component: DeviceQrCodes},
          ],
          menu: {
            path: "/refills",
            icon: (<LocalGasStationIcon style={{color: '#78A9FF'}}/>),
            tooltip: "Central de gestão de abastecimentos",
            name: "Central de Abastecimentos",
          },
        }
      ]
    },

    {
      title: "Controlo de passageiros",
      enabled:
        !temporaryAccess
        && web_permissions.includes('report_passenger_control'),
      items: [
        {
          enabled: web_permissions.includes('report_passenger_control'),
          routes: [
            {path: '/report/passenger_vehicle', component: ReportPassengerPerVehicle},
          ],
          menu: {
            path: "/report/passenger_vehicle",
            icon: (<GroupIcon style={{color: '#78A9FF'}}/>),
            tooltip: "Controlo de passageiros por veículo",
            name: "Recolhas por veíículo",
          },
        },
        {
          enabled: web_permissions.includes('report_passenger_control'),
          routes: [
            {path: '/report/passenger_passenger', component: ReportPassengerPerPassenger},
          ],
          menu: {
            path: "/report/passenger_passenger",
            icon: (<GroupIcon style={{color: '#78A9FF'}}/>),
            tooltip: "Controlo de passageiros por passageiro",
            name: "Recolhas por passageiro",
          },
        }
      ]
    },

    {
      title: t('management'),
      enabled: !temporaryAccess
      && (
        !web_permissions.includes('only_selected')
        || (web_permissions.includes('only_selected') && web_permissions.includes('module_management'))
      ),
      items: [
        {
          enabled: true,
          routes: [
            {path: '/management/summary', component: MaintenanceSummaryPage},
            {path: '/management/maintenances/:id?', component: MaintenancesPage},
            {path: '/management/maintenance/:id?', component: MaintenancePage},
            {path: '/management/costs/:id?', component: CostsPage},
            {path: '/management/cost/:id?', component: CostPage},
            {path: '/management/documents/:id?', component: DocsPage},
            {path: '/management/document/:id?', component: DocPage},
            {path: '/management/refills/:id?', component: RefillsPage},
            {path: '/management/refill/:id?', component: RefillPage},
            {path: '/management/settings/:id?', component: MaintenanceSettingsPage}, 
          ],
          menu: {
            path: "/management/summary",
            icon: (<BuildIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('maintenanceTip'),
            name: t('maintenance'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/report/management_analysis', component: MaintenanceAnalysisPage},
          ],
          menu: {
            path: "/report/management_analysis",
            icon: (<BarChart style={{color: '#78A9FF'}}/>),
            tooltip: t('costAnalysisTip'),
            name: t('costAnalysis'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/report/management_cost_list', component: MaintenanceCostListPage},
          ],
          menu: {
            path: "/report/management_cost_list",
            icon: (<AttachMoney style={{color: '#78A9FF'}}/>),
            tooltip: t('costListTip'),
            name: t('costList'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/report/monthly_liters', component: ReportMonthlyLiters},
          ],
          menu: {
            path: "/report/monthly_liters",
            icon: (<LocalGasStationIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('reportMonthlyLiters'),
            name: t('monthlyLiters'),
          },
        },
      ]
    },

    {
      title: t('settings'),
      enabled:
        !temporaryAccess
        && !web_permissions.includes('only_selected'),
      items: [
        {
          enabled: true,
          routes: [
            {path: '/settings/notifications', component: NotificationsPage},
            {path: '/settings/notification/:id?', component: NotificationPage},
          ],
          menu: {
            path: "/settings/notifications",
            icon: (<NotificationsIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('alertsTip'),
            name: t('alertNotifications'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/settings/device/:id?', component: DevicePage},
            {path: '/settings/devices', component: DevicesPage},
          ],
          menu: {
            path: "/settings/devices",
            icon: (<DriveEtaIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('vehiclesTip'),
            name: t('vehicles'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/settings/groups', component: GroupsPage},
            {path: '/settings/group/:id?', component: GroupPage},
          ],
          menu: {
            path: "/settings/groups",
            icon: (<FolderIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('groupsTip'),
            name: t('groups'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/settings/drivers', component: DriversPage},
            {path: '/settings/driver/:id?', component: DriverPage},
            {path: '/settings/driver_import', component: DriverImportPage},
          ],
          menu: {
            path: "/settings/drivers",
            icon: (<PersonIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('driversTip'),
            name: t('drivers'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/settings/geofences', component: GeofencesPage},
            {path: '/settings/geofence/:id?', component: GeofencePage},
          ],
          menu: {
            path: "/settings/geofences",
            icon: (<PlaceIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('zones'),
            name: t('zones'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/settings/user/:id?', component: UserPage},
            {path: '/settings/users', component: UsersPage},
          ],
          menu: {
            path: "/settings/users",
            icon: (<PeopleIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('usersTip'),
            name: t('users'),
          },
        },
        {
          enabled: true,
          routes: [
            {path: '/settings/temporary_link/:id?', component: TemporaryLinkPage},
            {path: '/settings/temporary_links', component: TemporaryLinksPage},
          ],
          menu: {
            path: "/settings/temporary_links",
            icon: (<VisibilityIcon style={{color: '#78A9FF'}}/>),
            tooltip: t('temporaryLinksTip'),
            name: t('temporaryLinks'),
          },
        },
      ]
    },
  ];
}

